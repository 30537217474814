@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: 'Lexend', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: "transparent";
  /* #212032; */
}

::-webkit-scrollbar-thumb {
  background: #1178f880;
}

::-webkit-scrollbar-thumb:hover {
  background: #1178f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div .button {
  margin: 0 15px 15px 0;
}
.video-contain video {
  object-fit: contain;
  overflow:hidden
}
textarea:focus{
  outline: none;
  border-color: none;
  box-shadow: none;
}

input:focus-visible{
  outline: none;
  border-color: none;
  box-shadow: none;
}

.video-cover video {
  position: absolute;
  object-fit: cover;
  
}

input[type="radio"]:checked + label span {
background-color: #fff; 
box-shadow: 0px 0px 0px 2px black inset;
}


input[type="radio"]:checked + label{
 color: #fff; 
}


.flying-emojis {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  z-index: 99;
}
.emoji {
  position: absolute;
  bottom: 0px;
  left: 50%;
  font-size: 48px;
  line-height: 1;
  width: 48px;
  height: 48px;
}
.emoji.wiggle-1 {
  animation: emerge 3s forwards,
    wiggle-1 1s ease-in-out infinite alternate;
}
.emoji.wiggle-2 {
  animation: emerge 3s forwards,
    wiggle-2 1s ease-in-out infinite alternate;
}
@keyframes emerge {
  to {
    bottom: 85%;
    opacity: 0;
  }
}
@keyframes wiggle-1 {
  from {
    margin-left: -50px;
  }
  to {
    margin-left: 50px;
  }
}
@keyframes wiggle-2 {
  from {
    margin-left: 50px;
  }
  to {
    margin-left: -50px;
  }
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.carousel-item img {
  display: block;
  height: auto;
  max-width: 100%;
}

.carousel-control {
  background: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 30px;
  height: 35px;
  line-height: 30px;
  position: absolute;
  top: 25%;
  -webkit-transform: translate(0, -50%);
  cursor: pointer;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width:35px;
  z-index: 10;
}

.carousel-control.prev {
  left: 2%;
}

.carousel-control.next {
  right: 2%;
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaaaaa;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}


/* Modal */
.modal-close {
  display: none;
}

.modal-mask {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.737);
  display: table;
  transition: opacity 0.3s ease;
  transform: scale(1);
  z-index: 9999;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  min-width: 450px;
  max-width: 450px;
  margin: 0px auto;
  padding: 45px 45px;
  background-color: #0E1433;
  border-radius: 0px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  animation: blowUpModal 0.9s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  border-radius: 26px;
  z-index: 999999999;
  overflow-y: visible;
  position: relative;
}

.modal-container.lg {
  min-width: 777px;
  max-width: 777px;
  padding: 0;
  height: max-content;
  border-radius: 10px;
  padding-bottom: 20px;
}

.modal .close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  border: none;
  display: inline-block;
  padding: 0px 0px;
  float: right;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: #0E1433;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  background: #fff;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1.2rem;
  z-index: 9999999999;
}

.modal .modal-header {
  background-color: transparent;
  height: 0px;
  padding: 0;
}


@media(max-width: 568px) {
  .modal-container {
    max-width: 350px !important;
    min-width: 300px !important;
    padding: 30px 20px;
  }
}